import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Text, Dimensions, PanResponder, Animated, StyleSheet, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ResizeMode, Video } from "expo-av";
import axios from 'axios';

const { width, height } = Dimensions.get('window');
const swipeThreshold = width / 2;
const outOfScreen = Math.sqrt(height * height + width * width);

export const Card = React.memo(({ profile, nextProfile, currentProfileID, nextProfileID, onSwipeOff, setTopCardVideos, skipToNextVideo, lock }) => {
  const topVideo = useRef(null);
  const nextVideo = useRef(null);
  const [error, setError] = useState(false);
  const [retries, setRetries] = useState(0);
  const [videoHeight, setVideoHeight] = useState(height);
  const [videoWidth, setVideoWidth] = useState(width);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [nextVideoLoading, setNextVideoLoading] = useState(false);

  const onLayout = ({ nativeEvent }) => {
    const { width, height } = nativeEvent.layout;
    setVideoWidth(width);
    setVideoHeight(height);
  };

  const loadVideos = useCallback(async () => {
    try {
      if (currentProfileID === profile?.ID && topVideo.current) {
        await topVideo.current.loadAsync({ uri: profile.ipfsUrl, overrideFileExtensionAndroid: 'mp4' });
        if (!isLoading) {
          await topVideo.current.playAsync();
        }
      }

      if (nextProfileID === nextProfile?.ID && nextVideo.current) {
        await nextVideo.current.loadAsync({ uri: nextProfile.ipfsUrl, overrideFileExtensionAndroid: 'mp4' });
      }
    } catch (error) {
      console.error('Failed to load video: ', error);
      setError(true);
    }
  }, [currentProfileID, profile, isLoading, nextProfileID, nextProfile]);

  const handleVideoPress = useCallback(async () => {
    if (isPlaying) {
      await topVideo.current.pauseAsync();
    } else {
      await topVideo.current.playAsync();
    }
    setIsPlaying(prev => !prev);
  }, [isPlaying]);

  const nextCard = useCallback(async (nextProfile, direction) => {
    setNextVideoLoading(true);
    if (modalResult.indexOf(nextProfile) > 0) {
      setVideoId(modalResult[modalResult.indexOf(nextProfile) - 1].ID);
      if (direction === 1) {
        console.log("liked", nextProfile);
        try {
          const res1 = await axios.post(apiURL + "/api/Upsocial/users/content/like", { videoId: nextProfile.ID, userEmail: (props.auth.user.curUser ? props.auth.user.curUser : localStorage.isUser) });
          const res2 = await axios.post(apiURL + "/api/Upsocial/content/like", { videoId: nextProfile.ID, userEmail: (props.auth.user.curUser ? props.auth.user.curUser : localStorage.isUser) });
          console.log(res1.data, res2.data);
        } catch (error) {
          console.warn(error);
        }
      } else {
        console.log("disliked", nextProfile);
        try {
          const res1 = await axios.post(apiURL + "/api/Upsocial/users/content/dislike", { videoId: nextProfile.ID, userEmail: (props.auth.user.curUser ? props.auth.user.curUser : localStorage.isUser) });
          const res2 = await axios.post(apiURL + "/api/Upsocial/content/dislike", { videoId: nextProfile.ID, userEmail: (props.auth.user.curUser ? props.auth.user.curUser : localStorage.isUser) });
          console.log(res1.data, res2.data);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    if (topVideo.current) {
      await topVideo.current.pauseAsync();
    }

    if (nextVideo.current) {
      await nextVideo.current.pauseAsync();
    }

    setNextVideoLoading(false);
  }, []);

  useEffect(() => {
    if (error && retries < 3) {
      const timeout = setTimeout(() => {
        console.log('Retrying video load');
        loadVideos();
        setRetries(prev => prev + 1);
      }, 9000);
      return () => clearTimeout(timeout);
    } else if (retries === 4) {
      skipToNextVideo();
    }
  }, [error, retries, loadVideos, skipToNextVideo]);

  useEffect(() => {
    loadVideos();

    return () => {
      if (topVideo.current) {
        topVideo.current.pauseAsync();
        topVideo.current.unloadAsync();
      }

      if (nextVideo.current) {
        nextVideo.current.pauseAsync();
        nextVideo.current.unloadAsync();
      }
    };
  }, [currentProfileID, nextProfileID, loadVideos]);

  const pan = useRef(new Animated.ValueXY()).current;
  const cardPanResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: Animated.event([null, { dx: pan.x, dy: pan.y }], {
          useNativeDriver: false,
        }),
        onMoveShouldSetPanResponderCapture: () => true,
        onPanResponderRelease: (_event, { dx }) => {
          const absDx = Math.abs(dx);
          const direction = absDx / dx;

          if (absDx > swipeThreshold) {
            Animated.spring(pan, {
              toValue: { x: outOfScreen * direction, y: 0 },
              useNativeDriver: false,
            }).start(() => onSwipeOff(profile, direction));
          } else {
            Animated.spring(pan, {
              toValue: { x: 0, y: 0 },
              friction: 4.5,
              useNativeDriver: false,
            }).start();
          }
        },
      }),
    [outOfScreen, pan, onSwipeOff, profile]
  );

  const rotateCard = useMemo(() => pan.x.interpolate({
    inputRange: [-200, 0, 200],
    outputRange: ['10deg', '0deg', '-10deg'],
  }), [pan.x]);

  const animatedStyle = useMemo(
    () => ({
      transform: [
        { translateX: pan.x },
        { translateY: pan.y },
        { rotate: rotateCard },
      ],
    }),
    [pan.x, pan.y, rotateCard]
  );

  const likeOpacity = useMemo(() => pan.x.interpolate({
    inputRange: [-width / 2, 0, width / 2],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp'
  }), [pan.x]);

  const nopeOpacity = useMemo(() => pan.x.interpolate({
    inputRange: [-width / 2, 0, width / 2.5],
    outputRange: [1, 0, 0],
    extrapolate: 'clamp'
  }), [pan.x]);

  const LoadingIndicator = useMemo(() => (
    <View style={styles.loadingContainer}>
      <ActivityIndicator size="large" color="#000000" />
    </View>
  ), []);

  return (
    <Animated.View
       {... (!lock && cardPanResponder.panHandlers)}
      style={[styles.card, animatedStyle]}
      onLayout={onLayout}>
      <Animated.View
        style={[
          styles.overlay,
          {
            opacity: likeOpacity,
            transform: [{ rotate: "-30deg" }],
          }
        ]}>
        <Text style={styles.overlayText}>UP</Text>
      </Animated.View>
      <Animated.View
        style={[
          styles.overlay2,
          {
            opacity: nopeOpacity,
            transform: [{ rotate: "30deg" }],
          }
        ]}>
        <Text style={styles.overlayText2}>Down</Text>
      </Animated.View>
      <TouchableOpacity activeOpacity={1} onPress={handleVideoPress}>
        {profile && profile.ipfsUrl ? (
          <Video
            ref={topVideo}
            style={{ width: videoWidth, height: videoHeight, overflow: "hidden" }}
            videoStyle={{ width: "100%", height: "100%" }}
            source={{ uri: profile.ipfsUrl, overrideFileExtensionAndroid: 'mp4' }}
            rate={1.0}
            volume={1.0}
            resizeMode={ResizeMode.CONTAIN}
            useNativeControls={false}
            isLooping
            shouldPlay={currentProfileID === profile.ID}
            onPlaybackStatusUpdate={(status) => {
              if (status.error) {
                console.error(status.error);
                setError(true);
              } else {
                setError(false);
                setTopCardVideos(topVideo);
              }
              setIsLoading(status.isBuffering);
            }}
          />
        ) : null}

        {nextProfile && nextProfile.ipfsUrl ? (
          <>
            {nextVideoLoading ? (
              LoadingIndicator
            ) : (
              <Video
                ref={nextVideo}
                style={{ width: videoWidth, height: videoHeight, overflow: "hidden" }}
                videoStyle={{ width: "100%", height: "100%" }}
                source={{ uri: nextProfile.ipfsUrl, overrideFileExtensionAndroid: 'mp4' }}
                onPlaybackStatusUpdate={(status) => {
                  if (status.error) {
                    console.error(status.error);
                    setError(true);
                  } else {
                    setError(false);
                  }
                }}
              />
            )}
          </>
        ) : null}
      </TouchableOpacity>
    </Animated.View>
  );
});

const styles = StyleSheet.create({
  card: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#000',
    margin: 1,
    borderWidth: 0,
    borderColor: 'lightgrey',
    borderRadius: 2,
  },
  overlay: {
    position: 'absolute',
    top: 120,
    left: 60,
    zIndex: 1000,
  },
  overlay2: {
    position: 'absolute',
    top: 120,
    right: 60,
    zIndex: 1000,
  },
  overlayText: {
    borderWidth: 1,
    padding: 10,
    fontSize: 38,
    fontWeight: '900',
    color: 'green'
  },
  overlayText2: {
    borderWidth: 1,
    padding: 10,
    fontSize: 38,
    fontWeight: '900',
    color: 'red',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Card;