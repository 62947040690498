import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { LinearGradient } from "expo-linear-gradient";
import { View, Text, ScrollView, StyleSheet, Image, TouchableOpacity, TextInput, Dimensions, Platform } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons, Feather, Ionicons, FontAwesome, AntDesign } from 'react-native-vector-icons';
import Modal from "react-native-modal";
import axios from 'axios';
import { apiURL } from '../../config/config';

const Settings = (props) => {
    const [opened, setOpened] = useState(false);
    const [title, setTitle] = useState("");

    const [confirmPass, setConfirmPass] = useState("");

    const [newPass, setNewPass] = useState("");
    const [pass_length, setPass_length] = useState(true);
    const [pass_num, setPass_num] = useState(true);
    const [pass_cap, setPass_cap] = useState(true);
    const [same_pass, setSame_pass] = useState(true);

    const createModal = (title) => {
        setOpened(true);
        setTitle(title);
    };

    const ValidatePassword = (e) => {
        if (e.length >= 8) {
            setPass_length(true);
        } else {
            setPass_length(false);
        }

        if (/[A-Z]/.test(e)) {
            setPass_cap(true);
        } else {
            setPass_cap(false);
        }

        if (/[0-9]/.test(e)) {
            setPass_num(true);
        } else {
            setPass_num(false);
        }

        if (pass_length && pass_cap && pass_num) {
            setNewPass(e);
        }
    };

    const confirmPassword = (e) => {
        if (e == newPass) {
            setConfirmPass(e);
            setSame_pass(true);
        } else {
            setSame_pass(false);
        }
    };

    const confirmNewPass = () => {
        if (newPass == confirmPass) {
            axios.post(apiURL + "/api/Upsocial/users/set-new-password", { userEmail: (props.auth.user.curUser ? props.auth.user.curUser : localStorage.isUser), password: newPass }, {
                "Access-Control-Allow-Origin": "*",
                'Access-Control-Allow-Headers': '*',
            }).then((res) => {
                if (res.data.status) {
                    alert(res.data.msg);
                    setOpened(false)
                } else {
                    alert(res.data.msg);
                    setOpened(false)
                }
            }).catch((err) => {
                console.warn(err);
                setOpened(false)
            });
        } else {
            alert("Please input correct confirm password!")
        }
    };

    return (
        <LinearGradient
            colors={['#2ab4fad9', '#1D2145']}
            style={styles.container}
        >
            <View style={styles.headersection}>
                <View style={styles.subheadersection}>
                    <TouchableOpacity style={styles.headerimage} onPress={() => props.navigation.navigate("Home")}>
                        <Image
                            source={require("../../../assets/logos/logo_wh.png")}
                            style={{ height: 30, width: 158 }}
                        />
                    </TouchableOpacity>
                    <View style={styles.iconsection}>
                        {/* <TouchableOpacity style={styles.iconbtn}>
                            <MaterialCommunityIcons name="cast" color="#fff" size={22} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.iconbtn}>
                            <MaterialCommunityIcons name="bell" color="#fff" size={22} />
                        </TouchableOpacity> */}
                        <TouchableOpacity style={styles.iconbtn}>
                            <MaterialIcons name="search" color="#fff" size={22} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.iconbtn} onPress={() => props.navigation.toggleDrawer()}>
                            <Feather name="menu" color="#fff" size={22} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <TouchableOpacity style={styles.back_btn} onPress={() => props.navigation.navigate("Home")}>
                <Ionicons name="arrow-back-sharp" color="#fff" size={30} />
                <Text style={{ color: "#fff", fontSize: 20, fontWeight: "bold" }}>Setting</Text>
            </TouchableOpacity>
            <View style={styles.settingGroup}>
                <Text style={styles.groupTitle}>Account Setting</Text>
                <Text style={styles.groupSummary}>change your account information</Text>
                {/* <TouchableOpacity style={styles.itemBox} onPress={() => createModal("Change Email")}>
                    <Text style={styles.itemTitle}>Change Email</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity> */}
                <TouchableOpacity style={styles.itemBox} onPress={() => createModal("Change Password")}>
                    <Text style={styles.itemTitle}>Change Password</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity>
            </View>
            <View style={styles.settingGroup}>
                <Text style={styles.groupTitle}>Legal Agreement</Text>
                <Text style={styles.groupSummary}>By using this app you have read and agree to the following:</Text>
                <TouchableOpacity style={styles.itemBox} onPress={() => createModal("Terms of Service")}>
                    <Text style={styles.itemTitle}>Terms of Service</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity>
                <TouchableOpacity style={styles.itemBox} onPress={() => createModal("User Agreement")}>
                    <Text style={styles.itemTitle}>User Agreement</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity>
                <TouchableOpacity style={styles.itemBox} onPress={() => createModal("Privacy")}>
                    <Text style={styles.itemTitle}>Privacy</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity>
                <TouchableOpacity style={styles.itemBox} onPress={() => createModal("About US")}>
                    <Text style={styles.itemTitle}>About US</Text>
                    <AntDesign name="right" size={20} color="#fff" />
                </TouchableOpacity>
            </View>
            <Modal
                isVisible={opened}
                animationIn={'slideInRight'}
                animationOut={'slideOutRight'}
                style={{ margin: 0, padding: 0 }}
            >
                <LinearGradient colors={['#2ab4fad9', '#1D2145']} style={styles.modalContainer}>
                    <TouchableOpacity style={styles.modalTitle} onPress={() => setOpened(false)}>
                        <AntDesign name="left" size={20} color="#fff" />
                        <Text style={{ color: "#fff", fontSize: 20 }}>{title}</Text>
                    </TouchableOpacity>
                    <ScrollView style={{ flex: 1, marginHorizontal: "10%", marginTop: 20 }}>
                        {title == "Terms of Service" && (
                            <View style={styles.mainContainer}>
                                <Text style={styles.sentenceContent}>Effective Date: 06-20-2023</Text>
                                <Text style={styles.sentenceContent}>These Terms of Service ("Terms") govern your use of the web and mobile application operated by Get Bit Labs LLC, doing business as "upsocial" (referred to as "we," "us," or "our"). By accessing or using our services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access or use our services.</Text>
                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>1. ACCEPTANCE OF TERMS</Text>
                                    <Text style={styles.sentenceContent}>1.1 By using our services, you affirm that you are at least 18 years old and capable of entering into a legally binding agreement. If you are using our services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.</Text>
                                    <Text style={styles.sentenceContent}>1.2 We reserve the right to modify or terminate these Terms at any time, without prior notice. Your continued use of our services after any such modifications constitutes your acceptance of the modified Terms.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>2. DESCRIPTION OF SERVICES</Text>
                                    <Text style={styles.sentenceContent}>2.1 Our services provide a decentralized video app that allows users to upload, share, and view videos. Our services also include various features, such as commenting, liking, and following other users.</Text>
                                    <Text style={styles.sentenceContent}>2.2 We may, at our sole discretion, modify or discontinue any or all aspects of our services at any time, without liability to you or any third party.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>3. USER ACCOUNTS</Text>
                                    <Text style={styles.sentenceContent}>3.1 In order to access certain features and functionalities of our services, you may need to create a user account. You agree to provide accurate, complete, and up-to-date information during the registration process.</Text>
                                    <Text style={styles.sentenceContent}>3.2 You are responsible for maintaining the confidentiality of your account credentials, including your username and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</Text>
                                    <Text style={styles.sentenceContent}>3.3 You are solely responsible for all activities that occur under your account. We shall not be liable for any loss or damage arising out of your failure to protect your account credentials.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>4. USER CONTENT</Text>
                                    <Text style={styles.sentenceContent}>4.1 Our services allow you to upload, post, and share content, including videos, comments, and other materials. You retain ownership of any intellectual property rights that you hold in your user content.</Text>
                                    <Text style={styles.sentenceContent}>4.2 By uploading, posting, or sharing user content, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, modify, adapt, publicly display, and perform your user content in connection with our services.</Text>
                                    <Text style={styles.sentenceContent}>4.3 You represent and warrant that you have all necessary rights, licenses, permissions, and consents to grant the aforementioned license to us and that your user content does not infringe or violate the rights of any third party.</Text>
                                    <Text style={styles.sentenceContent}>4.4 We reserve the right to remove or disable access to any user content that we believe, in our sole discretion, violates these Terms or is otherwise objectionable.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>5. PROHIBITED CONDUCT</Text>
                                    <Text style={styles.sentenceContent}>5.1 You agree not to engage in any of the following prohibited conduct while using our services:</Text>
                                    <Text style={styles.sentenceSelects}>a. Violating any applicable laws, regulations, or third-party rights.</Text>
                                    <Text style={styles.sentenceSelects}>b. Uploading, posting, or sharing any content that is obscene, hateful, defamatory, or infringing.</Text>
                                    <Text style={styles.sentenceSelects}>c. Impersonating any person or entity, or falsely stating or misrepresenting your affiliation with a person or entity.</Text>
                                    <Text style={styles.sentenceSelects}>d. Accessing or using our services for any illegal or unauthorized purpose.</Text>
                                    <Text style={styles.sentenceSelects}>e. Interfering with or disrupting the operation of our services or servers.</Text>
                                    <Text style={styles.sentenceSelects}>f. Engaging in any activity that could harm, disable, overburden, or impair our services.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>6. INTELLECTUAL PROPERTY</Text>
                                    <Text style={styles.sentenceContent}>6.1 Our services and all content, including logos, graphics, text, images, videos, and software, are protected by copyright, trademark, and other intellectual property laws. You may not copy, modify, distribute, or reproduce any part of our services or content without our prior written consent.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>7. DISCLAIMER OF WARRANTIES</Text>
                                    <Text style={styles.sentenceContent}>7.1 OUR SERVICES ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>8. LIMITATION OF LIABILITY</Text>
                                    <Text style={styles.sentenceContent}>8.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF OUR SERVICES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, LOSS OF DATA, OR INTERRUPTION OF BUSINESS.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>9. GOVERNING LAW AND JURISDICTION</Text>
                                    <Text style={styles.sentenceContent}>9.1 These Terms are governed by and construed in accordance with the laws of the state of Idaho, without regard to its conflict of laws principles.</Text>
                                    <Text style={styles.sentenceContent}>9.2 Any disputes arising out of or relating to these Terms or your use of our services shall be subject to the exclusive jurisdiction of the state and federal courts located in Idaho.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>10. GENERAL PROVISIONS</Text>
                                    <Text style={styles.sentenceContent}>10.1 These Terms constitute the entire agreement between you and us concerning the subject matter herein and supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written.</Text>
                                    <Text style={styles.sentenceContent}>10.2 Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</Text>
                                    <Text style={styles.sentenceContent}>10.3 If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall be enforced to the fullest extent possible, and the remaining Terms shall remain in full force and effect.</Text>
                                </View>

                                <Text style={styles.sentenceContent}>By using our services, you acknowledge that you have read, understood, and agreed to these Terms of Service.</Text>
                            </View>
                        )}
                        {title == "User Agreement" && (
                            <View style={styles.mainContainer}>
                                <Text style={styles.sentenceContent}>Effective Date: 06-20-2023</Text>
                                <Text style={styles.sentenceContent}>This User Agreement ("Agreement") governs the relationship between Get Bit Labs LLC, doing business as "upsocial" (referred to as "we," "us," or "our"), and users of our web and mobile application (referred to as "you" or "your"). By accessing or using our services, you agree to be bound by this Agreement. If you do not agree with any part of this Agreement, you may not access or use our services.</Text>
                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>1. ACCEPTANCE OF TERMS</Text>
                                    <Text style={styles.sentenceContent}>1.1 By using our services, you affirm that you are at least 18 years old and capable of entering into a legally binding agreement. If you are using our services on behalf of an organization, you represent that you have the authority to bind that organization to this Agreement.</Text>
                                    <Text style={styles.sentenceContent}>1.2 We reserve the right to modify or terminate this Agreement at any time, without prior notice. Your continued use of our services after any such modifications constitutes your acceptance of the modified Agreement.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>2. USER OBLIGATIONS</Text>
                                    <Text style={styles.sentenceContent}>2.1 You agree to use our services in compliance with all applicable laws, regulations, and this Agreement. You are solely responsible for your conduct and any content you upload, post, or share through our services.</Text>
                                    <Text style={styles.sentenceContent}>2.2 You agree not to engage in any of the following prohibited activities while using our services:</Text>
                                    <Text style={styles.sentenceSelects}>a. Violating any applicable laws, regulations, or third-party rights.</Text>
                                    <Text style={styles.sentenceSelects}>b. Uploading, posting, or sharing any content that is obscene, hateful, defamatory, or infringing.</Text>
                                    <Text style={styles.sentenceSelects}>c. Impersonating any person or entity, or falsely stating or misrepresenting your affiliation with a person or entity.</Text>
                                    <Text style={styles.sentenceSelects}>d. Accessing or using our services for any illegal or unauthorized purpose.</Text>
                                    <Text style={styles.sentenceSelects}>e. Interfering with or disrupting the operation of our services or servers.</Text>
                                    <Text style={styles.sentenceSelects}>f. Engaging in any activity that could harm, disable, overburden, or impair our services.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>3. ACCOUNT SECURITY</Text>
                                    <Text style={styles.sentenceContent}>3.1 In order to access certain features and functionalities of our services, you may need to create a user account. You agree to provide accurate, complete, and up-to-date information during the registration process.</Text>
                                    <Text style={styles.sentenceContent}>3.2 You are responsible for maintaining the confidentiality of your account credentials, including your username and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</Text>
                                    <Text style={styles.sentenceContent}>3.3 You are solely responsible for all activities that occur under your account. We shall not be liable for any loss or damage arising out of your failure to protect your account credentials.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>4. INTELLECTUAL PROPERTY</Text>
                                    <Text style={styles.sentenceContent}>4.1 Our services and all content available through our services are protected by copyright, trademark, and other intellectual property laws. You acknowledge and agree that we retain all rights, title, and interest in and to our services and content.</Text>
                                    <Text style={styles.sentenceContent}>4.2 You may not copy, modify, distribute, or reproduce any part of our services or content without our prior written consent.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>5. DISCLAIMERS OF WARRANTIES</Text>
                                    <Text style={styles.sentenceContent}>5.1 OUR SERVICES ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>6. LIMITATION OF LIABILITY</Text>
                                    <Text style={styles.sentenceContent}>6.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF OUR SERVICES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, LOSS OF DATA, OR INTERRUPTION OF BUSINESS.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>7. GOVERNING LAW AND JURISDICTION</Text>
                                    <Text style={styles.sentenceContent}>7.1 This Agreement is governed by and construed in accordance with the laws of the state of Idaho, without regard to its conflict of laws principles.</Text>
                                    <Text style={styles.sentenceContent}>7.2 Any disputes arising out of or relating to this Agreement or your use of our services shall be subject to the exclusive jurisdiction of the state and federal courts located in Idaho.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>8. GENERAL PROVISIONS</Text>
                                    <Text style={styles.sentenceContent}>8.1 This Agreement constitutes the entire agreement between you and us concerning the subject matter herein and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written.</Text>
                                    <Text style={styles.sentenceContent}>8.2 Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.</Text>
                                    <Text style={styles.sentenceContent}>8.3 If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall be enforced to the fullest extent possible, and the remaining Agreement shall remain in full force and effect.</Text>
                                </View>

                                <Text style={styles.sentenceContent}>By using our services, you acknowledge that you have read, understood, and agreed to this User Agreement.</Text>
                            </View>
                        )}
                        {title == "Privacy" && (
                            <View style={styles.mainContainer}>
                                <Text style={styles.sentenceContent}>Effective Date: 06-20-2023</Text>
                                <Text style={styles.sentenceContent}>This Privacy Policy (“Policy”) outlines how Get Bit Labs LLC, doing business as "upsocial" (referred to as "we", "us", or "our") collects, uses, discloses, and protects your personal information when you access or use our web and mobile application (referred to as "services"). By accessing or using our services, you agree to the collection and use of your personal information as described in this Policy. If you do not agree with any part of this Policy, you may not access or use our services.</Text>
                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>1. INFORMATION WE COLLECT</Text>
                                    <Text style={styles.sentenceContent}>1.1 Personal Information</Text>
                                    <Text style={styles.sentenceSelects}>We may collect personal information that you provide to us directly, such as your name, email address, username, and password when creating a user account or using certain features of our services.</Text>
                                    <Text style={styles.sentenceContent}>1.2 Usage Information</Text>
                                    <Text style={styles.sentenceSelects}>We may automatically collect information about your interaction with our services, including your IP address, device information, browser type, operating system, and access timestamps. This information helps us analyze trends, administer our services, and improve the user experience.</Text>
                                    <Text style={styles.sentenceContent}>1.3 Analytics and Cookies</Text>
                                    <Text style={styles.sentenceSelects}>We use analytics tools and cookies to gather information about your usage patterns on our services. This includes information about the pages you visit, the duration of your visit, and the actions you take. The analytics and cookies data helps us understand user preferences, personalize content, and optimize our services. You can manage your cookie preferences through your browser settings.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>2. USE OF INFORMATION</Text>
                                    <Text style={styles.sentenceContent}>2.1 We may use your personal information to:</Text>
                                    <Text style={styles.sentenceSelects}>a. Provide and improve our services to you.</Text>
                                    <Text style={styles.sentenceSelects}>b. Communicate with you, including responding to your inquiries and providing customer support.</Text>
                                    <Text style={styles.sentenceSelects}>c. Send you promotional and marketing materials related to our services, subject to your consent.</Text>
                                    <Text style={styles.sentenceContent}>2.2 We may use usage information and analytics data to:</Text>
                                    <Text style={styles.sentenceSelects}>a. Analyze and monitor user behavior and trends.</Text>
                                    <Text style={styles.sentenceSelects}>b. Measure the effectiveness of our services and marketing campaigns.</Text>
                                    <Text style={styles.sentenceSelects}>c. Customize and personalize content and advertisements.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>3. DISCLOSURE OF INFORMATION</Text>
                                    <Text style={styles.sentenceContent}>3.1 We may disclose your personal information to trusted third-party service providers who assist us in operating our services, such as hosting providers, customer support providers, payment processors, and analytics providers.</Text>
                                    <Text style={styles.sentenceContent}>3.2 We may also disclose your personal information:</Text>
                                    <Text style={styles.sentenceSelects}>a. In response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</Text>
                                    <Text style={styles.sentenceSelects}>b. To enforce our rights or protect our property.</Text>
                                    <Text style={styles.sentenceSelects}>c. To prevent or investigate possible wrongdoing in connection with our services.</Text>
                                    <Text style={styles.sentenceSelects}>d. To protect the personal safety of users of our services or the public.</Text>
                                    <Text style={styles.sentenceContent}>3.3 We may share aggregated or de-identified information that does not personally identify you.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>4. DATA RETENTION</Text>
                                    <Text style={styles.sentenceContent}>4.1 We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>5. INFORMATION SECURITY</Text>
                                    <Text style={styles.sentenceContent}>5.1 We implement reasonable security measures to protect your personal information from unauthorized access, use, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>6. CHILDREN'S PRIVACY</Text>
                                    <Text style={styles.sentenceContent}>6.1 Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>7. THIRD-PARTY LINKS AND SERVICES</Text>
                                    <Text style={styles.sentenceContent}>7.1 Our services may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such third-party websites or services. We encourage you to review the privacy policies of those third parties before providing any personal information.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>8. CALIFORNIA RESIDENTS</Text>
                                    <Text style={styles.sentenceContent}>8.1 If you are a California resident, you may have additional rights regarding your personal information. Please refer to our separate California Privacy Rights Notice for more information on how to exercise those rights.</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>9. CONTACT US</Text>
                                    <Text style={styles.sentenceContent}>9.1 If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at [support AT upsocial.com ].</Text>
                                </View>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>10. CHANGES TO THIS POLICY</Text>
                                    <Text style={styles.sentenceContent}>10.1 We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Policy on our website or through other reasonable means. Your continued use of our services after any such modifications constitutes your acceptance of the revised Privacy Policy.</Text>
                                </View>

                                <Text style={styles.sentenceContent}>By using our services, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</Text>
                            </View>
                        )}
                        {title == "About US" && (
                            <View style={styles.mainContainer}>
                                <Text style={styles.sentenceContent}>Get Bit Labs LLC - Revolutionizing the Digital Landscape</Text>
                                <Text style={styles.sentenceContent}>At Get Bit Labs LLC, we are driven by a passion for technology and a commitment to empowering individuals in the digital space. Our founder, Andrew Arnott, brings a wealth of experience and a relentless entrepreneurial spirit to our organization.</Text>
                                <Text style={styles.sentenceContent}>Andrew's journey in technology began at a young age, sparking a fascination that would shape his future endeavors. From building his own PCs and running bulletin board systems (BBSs) to launching his first software-as-a-service (SaaS) in college, Andrew's early ventures laid the foundation for his future success.</Text>
                                <Text style={styles.sentenceContent}>While Andrew pursued a degree in Aerospace Sciences and worked as an Air Traffic Controller, his entrepreneurial spirit remained undeterred. He ventured into various side businesses, honing his skills and gaining valuable insights along the way. Despite challenges and setbacks, Andrew persevered, continuously educating himself and pushing the boundaries of what was possible.</Text>
                                <Text style={styles.sentenceContent}>In 2008, Andrew's focus shifted to Android apps, an emerging frontier at the time. He developed one of the first solitaire apps on the platform, gaining considerable traction and amassing over 1 million downloads. Andrew continued to develop and acquire more apps, but unforeseen circumstances would soon test his resilience.</Text>
                                <Text style={styles.sentenceContent}>A trademark issue resulted in Google banning Andrew's entire app catalog overnight, creating a profound realization about the vulnerabilities of relying solely on big tech. Undeterred, Andrew pivoted again and found success in selling on Amazon. Despite an initial setback, he appealed and quickly achieved 7-figure sales within months.</Text>
                                <Text style={styles.sentenceContent}>Through his experience on Amazon, Andrew became acutely aware of the challenges faced by sellers and the limitations of relying on a single platform. Determined to create a better future for digital entrepreneurs, he sought to develop a decentralized solution that empowers users while circumventing the pitfalls of centralized control.</Text>
                                <Text style={styles.sentenceContent}>This vision has manifested itself in our latest venture, "upsocial," a cutting-edge web3 app that revolutionizes the digital landscape. By harnessing the potential of blockchain technology and decentralization, upsocial offers a platform for content creators and users to connect, share, and thrive while ensuring their rights and ownership are protected.</Text>
                                <Text style={styles.sentenceContent}>Behind the scenes, our brilliant developers utilize their expertise to build and enhance the functionality of our web and mobile applications. They work tirelessly to ensure our platform remains cutting-edge, secure, and user-friendly.</Text>
                                <Text style={styles.sentenceContent}>At Get Bit Labs LLC, we believe true innovation lies in empowering individuals. We are committed to creating a platform that operates independently of big tech, where users are respected and honored. By leveraging the power of web3, we aim to foster a community-driven ecosystem that promotes growth, prosperity, and positive change.</Text>
                                <Text style={styles.sentenceContent}>In our pursuit of excellence, we prioritize user satisfaction, privacy, and security. We are continually evolving to meet the ever-changing needs of our community and the digital landscape. With a rock-solid realtor wife by his side, Andrew continues to pursue his passion for technology while nurturing family and aspiring to make a positive impact in the world.</Text>

                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>Contact Information:</Text>
                                    <Text style={styles.sentenceSelects}>Phone: 208-366-5905</Text>
                                    <Text style={styles.sentenceSelects}>GET BIT LABS LLC</Text>
                                    <Text style={styles.sentenceSelects}>9169 W State St #2233,</Text>
                                    <Text style={styles.sentenceSelects}>Garden City, ID 83714</Text>
                                </View>
                            </View>
                        )}
                        {title == "Change Password" && (
                            <View style={styles.mainContainer}>
                                <View style={styles.sectionGroup}>
                                    <Text style={styles.sentenceTitle}>Change your Password</Text>
                                    <View style={styles.TextGroupView}>
                                        <View style={styles.TextView}>
                                            <TextInput placeholder="New Password" placeholderTextColor="#adb2b6"
                                                secureTextEntry={true}
                                                style={pass_length && pass_num && pass_cap ? styles.TextInput : styles.Error_TextInput} onChangeText={(e) => ValidatePassword(e)} />
                                            {!pass_length && <Text style={{ color: "red", fontSize: 16 }}>Password has more than 8 characters!</Text>}
                                            {!pass_num && <Text style={{ color: "red", fontSize: 16 }}>Password has a number!</Text>}
                                            {!pass_cap && <Text style={{ color: "red", fontSize: 16 }}>Password has a capital letter!</Text>}
                                        </View>
                                        <View style={styles.TextView}>
                                            <TextInput placeholder="Confirm Password" placeholderTextColor="#adb2b6"
                                                secureTextEntry={true}
                                                style={pass_length && pass_num && pass_cap ? styles.TextInput : styles.Error_TextInput} onChangeText={(e) => confirmPassword(e)} />
                                            {!same_pass && <Text style={{ color: "red", fontSize: 16 }}>Password is Incorrect !</Text>}
                                        </View>
                                        <View style={styles.TextView}>
                                            <TouchableOpacity style={styles.regbtn} onPress={confirmNewPass}>
                                                <Text style={styles.regbtntext}>Confirm</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}
                    </ScrollView>
                </LinearGradient>
            </Modal>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        position: "relative"
    },
    headersection: {
        height: Dimensions.get("window").height * 0.08,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    subheadersection: {
        width: "calc(100% - 30px)",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerimage: {
        flex: 1
    },
    iconsection: {
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    iconbtn: {
        marginLeft: 10,
    },
    back_btn: {
        flexDirection: "row",
        gap: 30,
        marginTop: 50
    },
    settingGroup: {
        flexDirection: "column",
        gap: 10,
        marginTop: 30,
        paddingHorizontal: 30
    },
    groupTitle: {
        color: "#fff",
        fontSize: 18,
        fontWeight: "bold"
    },
    groupSummary: {
        color: "#fff",
        fontSize: 12,
    },
    itemBox: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    itemTitle: {
        color: "#fff",
        fontSize: 15,
    },
    modalContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        paddingVertical: 50,
    },
    modalTitle: {
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
    },
    mainContainer: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
        gap: 10
    },
    sectionGroup: {
        flexDirection: "column",
        gap: 15
    },
    sentenceTitle: {
        color: "#fff",
        fontSize: 20
    },
    sentenceContent: {
        color: "#fff",
        fontSize: 14
    },
    sentenceSelects: {
        color: "#fff",
        fontSize: 11.5
    },
    TextGroupView: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 45,
        marginBottom: 160
    },
    TextView: {
        width: "100%",
        alignItems: "center"
    },
    regbtn: {
        backgroundColor: "#9c26b0",
        width: "90%",
        marginVertical: 10,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10
    },
    regbtntext: {
        color: "#fff",
        fontSize: 22,
        textTransform: "uppercase"
    },
    TextInput: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        fontSize: 16,
        borderBottomColor: "#3b8ad0",
        borderBottomWidth: 2,
        width: "90%",
        color: "#fff",
        marginVertical: 10
    },
    Error_TextInput: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        fontSize: 16,
        borderBottomColor: "red",
        borderBottomWidth: 2,
        width: "90%",
        color: "#fff",
        marginVertical: 10
    },
});

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(Settings);