import React, { useEffect, useState, memo } from "react";
import { StyleSheet, View, StatusBar, Image, Dimensions } from "react-native";
import { connect } from "react-redux";
import ProfileManage from "./pages/ProfileManage";
import Dashboard from "./pages/Dashboard";
import toast, { Toaster } from 'react-hot-toast';

const MemoizedProfileManage = memo(ProfileManage);
const MemoizedDashboard = memo(Dashboard);

const Main = (props) => {
    const [isAuth, setIsAuth] = useState(false);
    
    useEffect(() => {
        if (localStorage.getItem("isUser")) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [props.auth]);
    
    return (
        <View style={styles.container}>
            <Toaster />
            <StatusBar />
            {isAuth ? <MemoizedProfileManage /> : <MemoizedDashboard />}
            {props.loading.loading && (
                <View style={styles.loadingView}>
                    <Image
                        source={require("../assets/loading.gif")}
                        style={{ width: 140, height: 140 }}
                    />
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: "relative",
    },
    loadingView: {
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: Dimensions.get('window').height,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10000,
    }
});

const mapStateToProps = (state) => ({
    auth: state.auth,
    loading: state.loading
});

export default connect(mapStateToProps, {})(Main);